// User styles
//
// This is the place where you should place all additional styles and imports.
// This will allow you to take advantage of future updates with lower time consumed on the upgrade process.

// Buttons

.btn-outline-gray {
  color: #707070;
  background-color: $white;
  border-color: $gray-300;
  &:hover,
  &:focus,
  &.active {
    color: $blue !important;
    background-color: rgba(47, 128, 237, 0.1) !important;
    border-color: rgba(47, 128, 237, 0.2) !important;
  }
}

.btn-soft-primary {
  background-color: rgba(47, 128, 237, 0.1);
  border: 1px solid rgba(47, 128, 237, 0.1);
}

.dropend .btn:focus {
  color: $gray-500;
}

// Sidebar

.sidebar,
.wrapper,
.header {
  background-color: $gray-100;
  border: none;
}

.sidebar {
  padding: 1rem;
}

.sidebar-brand {
  background: none;
  border-bottom: 1px solid $gray-400;
  margin-bottom: 2rem;
  img {
    margin: 1.5rem;
  }
}

.sidebar-nav {
  flex: none;
  .nav-link {
    color: $gray-800;
    font-size: 1rem;
    &:hover {
      color: $gray-800;
      .nav-icon {
        color: $gray-800;
      }
    }
    &.active {
      .nav-icon {
        color: $white;
      }
      &:hover {
        color: $white;
      }
    }
  }
  .nav-icon {
    color: $gray-800;
  }
}

.sidebar-brand-container {
  padding: 1rem;
  .sidebar-collapse {
    background-color: $gray-200;
    width: 1.5rem;
    height: 2rem;
    position: absolute;
    right: 0;
    top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .remixicon-icon {
      color: $gray-800;
    }
  }
}

.sidebar-fixed.sidebar-narrow-unfoldable {
  width: 8rem !important;
}

.sidebar-narrow-unfoldable:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 8rem;
}

.sidebar-narrow-unfoldable {
  .sidebar-brand-full {
    display: none;
  }
  .sidebar-brand-narrow {
    display: block;
  }
  .nav-pills .nav-link.active {
    width: 4rem;
  }
  .sidebar-nav {
    width: 4rem;
  }
}

.header-nav {
  .nav-icon {
    background-color: $white;
    border-radius: 0.313rem;
    border: 1px solid $border-color;
    margin: 0.313rem;
    width: 3.125rem;
    height: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin: 0;
      height: 1rem;
    }
  }
}

// Backround

.bg-light {
  background-color: $gray-200 !important;
  border: 1px solid #e5e5e5;
}

.bg-light-success {
  background-color: rgba(39, 174, 96, 0.1) !important;
  border: 1px solid #27ae60 !important;
}

// Typography

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  font-weight: 400;
}

.header-underline {
  border: 2px solid $gray-600;
  background-color: $gray-600;
  width: 3rem;
  border-radius: $border-radius;
  margin-bottom: 1.25rem;
}

// Forms

.input-group {
  input.prepend {
    border-left: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    padding-left: 0;
  }
  input.append {
    border-right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding-right: 0;
    + .input-group-text {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
  .input-group-text {
    background-color: $white;
    color: $gray-500;
    padding: 0.375rem 1.25rem;
    &.disabled {
      background-color: #eff2f7 !important;
      border-color: var(--cui-input-disabled-border-color, #b1b7c1) !important;
      opacity: 1;
    }
  }
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-radius: $border-radius;
  border-left: 0;
}

.form-control:focus {
  box-shadow: none;
  border-color: $border-color;
}

.form-check {
  padding-left: 1.5rem;
}

.form-check-input {
  margin-top: 0.4rem;

  &:focus {
    border-color: $border-color;
    box-shadow: $box-shadow;
  }
  &:checked {
    background-color: $primary;
    border-color: $primary;
  }
}

.react-select {
  &.is-invalid > div:first-of-type {
    border-color: $red;
  }
}

// Cards

.student-progress {
  width: 60px;
  height: 4px;
}

.card-body {
  color: $gray-700;
}

// Course Outline

ul.topics {
  list-style: none;
  padding: 0;

  li {
    cursor: pointer;
    padding: 0;
    margin-bottom: 0.5rem;

    a.active .card,
    a.active + .card.subtopics,
    &:hover > a > .card,
    &:hover > .card {
      border-color: $primary;
    }

    a.active span {
      position: absolute;
      top: 10%;
      left: 0;
      height: 80%;
      border: 2px solid $primary;
      background-color: $primary;
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }

    &.has-subtopics a > .card {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 0;
    }
  }

  .subtopics {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        font-weight: 400;
        cursor: pointer;
        color: $gray-800;
        margin: 0.5rem 0;

        .active span {
          position: relative;
          left: -16px;
          height: 80%;
          border: 2px solid $primary;
          border-top-right-radius: 2rem;
          border-bottom-right-radius: 2rem;
        }
      }
    }
  }
}

// Charts

.chart-icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-radius: 4px;
  &.blue {
    background: rgba(47, 128, 237, 0.2);
    border: 0.5px solid #2f80ed;
    color: #2f80ed;
  }
  &.green {
    background: rgba(39, 174, 96, 0.2);
    border: 0.5px solid #27ae60;
    color: #27ae60;
  }
  &.orange {
    background: rgba(242, 153, 74, 0.2);
    border: 0.5px solid #f2994a;
    color: #f2994a;
  }
  &.purple {
    background: rgba(124, 96, 255, 0.2);
    border: 0.5px solid #7c60ff;
    color: #7c60ff;
  }
}

// Chats

.chat {
  border-radius: $border-radius-lg;
  min-height: 480px;
  margin: 0;
}

.chat-stretch {
  height: calc(0.7 * 100vh) !important;
}

.chat-box {
  display: flex;
  flex-direction: column;
  .chat-history {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .chat-input {
    display: flex;
  }
}

.chat-message {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  .chat-sender {
    padding: 15px;
    background-color: $gray-300;
    border-radius: $border-radius-lg;
    border-bottom-right-radius: 0;
  }
  .chat-recipient {
    padding: 15px;
    color: $white;
    background-color: $blue;
    border-radius: $border-radius-lg;
    border-bottom-left-radius: 0;
  }
}

.chat-divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  .line {
    width: 40%;
  }
  .box {
    width: 80px;
    height: 40px;
    padding: 10px 20px;
    background: $gray-300;
    border-radius: $border-radius-lg;
  }
}

.chat-contact {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid $gray-300;
  cursor: pointer;
  &.active {
    background: linear-gradient(
      90deg,
      rgba(47, 128, 237, 0.2) 0%,
      rgba(47, 128, 237, 0) 100%
    );
    border-left: 2px solid #2f80ed;
  }
}

// Files

.file-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  background: $gray-500;
  &.pdf {
    background: $orange;
  }
  &.doc {
    background: $blue;
  }
  &.txt {
    background: $teal;
  }
  &.zip {
    background: $pink;
  }
}

// Payments

.payment-shown {
  .payment-row {
    color: $blue !important;
    background-color: rgba(47, 128, 237, 0.2);
  }
  border: 1px solid rgba(47, 128, 237, 0.2);
}

// Timetable

.monthly-timetable {
  .rbc-month-header {
    color: $blue;
    margin-bottom: 1rem;
    .rbc-header {
      font-size: 100%;
    }
  }
  .rbc-month-view,
  .rbc-header,
  .rbc-month-row,
  .rbc-day-bg {
    border: none;
  }
  .rbc-month-row {
    margin-bottom: 8px;
  }
  .rbc-off-range-bg {
    background-color: transparent;
  }
  .rbc-date-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rbc-today {
    background: transparent;
  }
  .rbc-now {
    background: rgba(0, 150, 255, 0.2);
    border-radius: 3px;
    padding: 0;
    span {
      color: $blue !important;
      padding: 0;
    }
  }
  .dot {
    display: inline-block;
    border-radius: 50%;
    background-color: $blue;
    height: 8px;
    width: 8px;
    margin: 2px;
  }
  .rbc-event {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    outline: none;
  }
  .rbc-row-segment:focus-visible {
    outline: none;
  }
  .rbc-event:focus-visible {
    outline: none;
  }
  .rbc-event-content:focus-visible {
    outline: none;
  }
  .rbc-show-more {
    font-size: 8px;
    text-align: center;
  }
}

.daily-timetable {
  .rbc-event {
    border-radius: 0;
    border: 2px solid $blue;
    border-right: none;
    border-top: none;
    border-bottom: none;
    padding: 0.5rem 1rem;
    &.active {
      background: linear-gradient(
        90deg,
        rgba(39, 174, 96, 0.2) 0%,
        rgba(39, 174, 96, 0) 100%
      );
      border-color: #27ae60;
      color: #27ae60;
    }
  }
  .rbc-event-label {
    display: none;
  }
  .rbc-time-view {
    border: 0;
  }
  .rbc-time-header-content,
  .rbc-time-content > * + * > * {
    border-left: 0;
  }
  .rbc-time-header {
    display: none;
  }
  .rbc-timeslot-group:nth-child(odd) {
    border-bottom: 1px dashed #ddd;
  }
}

.weekly-timetable {
  &.rbc-calendar {
    height: 885px;
  }
  .rbc-header {
    padding: 10px;
    font-weight: normal;
  }
  .rbc-header,
  .rbc-time-header-content,
  .rbc-header + .rbc-header,
  .rbc-day-bg + .rbc-day-bg {
    border: 0;
  }
  .rbc-time-header {
    background: $gray-100;
    border-top: 1px solid #ddd;
  }
  .rbc-time-view {
    border: 0;
  }
  .rbc-event {
    border: none;
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    padding: 5%;
  }
  .rbc-event-allday {
    padding: 2%;
  }
  .rbc-event-label {
    display: none;
  }
  .rbc-timeslot-group:nth-child(odd) {
    border-bottom: 1px dashed $gray-100;
  }
  .rbc-time-view .rbc-row {
    min-height: 50px;
  }
  .rbc-allday-cell {
    .rbc-row {
      min-height: 0;
    }
  }
  .rbc-label {
    padding: 0px 20px;
  }
  .rbc-timeslot-group:nth-child(even) {
    .rbc-label {
      display: none;
    }
  }
}

// Popovers

.popover,
.popover-body {
  max-width: 340px;
}

// Flatpickr Calendar

.flatpickr-calendar {
  border: 0;
  width: auto;
  margin-top: -2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: $white;
  box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.05);
  border-radius: 0.375rem;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  display: none;
}

.flatpickr-months {
  background-color: $white;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.flatpickr-months .flatpickr-month {
  height: 60px;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  width: 35px;
  height: 35px;
  padding: 0;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #152c5b;
  top: 13px;
  transition: background-color 0.4s linear;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  fill: rgba(21, 44, 91, 0.7);
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #152c5b;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #152c5b;
}
.flatpickr-months .flatpickr-prev-month {
  margin-left: 15px;
}
.flatpickr-months .flatpickr-next-month {
  margin-right: 15px;
}

.flatpickr-current-month {
  font-size: 1.125rem;
  color: #152c5b;
  padding-top: 18px;
}
.flatpickr-day {
  font-size: 0.875rem;
  border: 0;
  color: #4a5568;
  border-radius: 0.375rem;
}
.flatpickr-day.today {
  color: $blue !important;
}
.flatpickr-day.today:hover {
  background-color: transparent;
  color: $blue;
}
.flatpickr-day:hover {
  background-color: transparent;
  color: $blue;
}
.flatpickr-day.selected {
  background-color: $blue;
  color: $white !important;
}
.flatpickr-day.selected:hover {
  background-color: $blue;
  color: $white;
}

.flatpickr-time {
  border-top: 1px solid #eaecf3;
}

// Dots

.color-dots {
  display: flex;
  align-items: center;
  .dot {
    border-radius: 50%;
    background-color: $blue;
    height: 32px;
    width: 32px;
    margin-right: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Lazy load image

.lazy-load-image-background {
  display: flex !important;
}

// Pagination

.paginate {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin-right: 1rem;
    a,
    a:not([href]):not([class]) {
      display: flex;
      align-items: center;
      color: $gray-500;
      font-weight: 500;
    }
  }
  .link-disabled {
    a {
      color: $gray-400 !important;
    }
  }
  .link-active {
    a {
      color: $primary !important;
      font-weight: 700;
    }
  }
}

// Navs

.nav-item {
  a {
    color: $gray-700;
  }
}

// Header line with text

.hr-line-with-text {
  display: flex;
  align-items: center;
  .hr-line {
    width: 100%;
    position: relative;
    margin: 15px 5px;
    border-bottom: 1px solid #4f4f4f;
  }
  .hr-text {
    position: relative;
    color: #4f4f4f;
  }
}

// Tutor List

.tutor-list-item {
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: $primary;
  }
}
